import { RedrowEnvironmentType } from "@redrow/utilities";
import { ExternalLoginAppConfigurationBase } from "./external-login-app.interface";
import buildSettings from "../../build-settings.json";

const THIS_ENVIRONMENT: RedrowEnvironmentType = "Local";

// Left as baseConfig for a reason!
export const CONFIGURATION_BASE: ExternalLoginAppConfigurationBase = {
	LOCAL_VERSION: `${buildSettings.pipelineBuildNumber} ${buildSettings.pipelineBuildName}`,
	env: THIS_ENVIRONMENT,
	Version: "1.01",
	Revision: "a",

	// Test key from https://developers.google.com/recaptcha/docs/faq#im-using-content-security-policy-csp-on-my-website-how-can-i-configure-it-to-work-with-recaptcha
	GoogleRecaptchaSiteKey: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",

	appId: "oauth-ext"


};
